import React, {useState} from 'react';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withFirebase} from "../Firebase";
import 'antd/dist/antd.css';
import {withRouter} from "react-router-dom";
import CustomerInfo from "./CustomerInfo";
import { WHO_STATE, THANK_STATE} from "../../constants/actions";
import Thanks from '../Thanks';

const styles= {
  box:{
    color: "#000",
    border: "4px solid #ffca6e",
    borderRadius: "14px",
    paddingLeft:"15px",
    paddingBottom:"34px",
    paddingTop:"12px",
    backgroundColor:"#faf5ea",
  },
  outerBox:{
    fontSize:"40px",
    fontWeight:"300",
    maxWidth:"480px",
    width:"480px",
    paddingLeft:"15px",
    paddingRight:"15px",
    paddingBottom:"12px",
    paddingTop:"12px",
    borderRadius: "24px",
    backgroundColor:"#faf5ea",
    WebkitBoxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    MozBoxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    marginBottom:"90px",
  },
  carbonBox: {
    background: "radial-gradient(#e8a22c 15%, transparent 16%) 0 0, radial-gradient(#e8a22c 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px",
    backgroundColor: "#ffca6e",
    backgroundSize: "16px 16px"
  }
};


const Checkout=(props)=> {
  return (
    <div style={styles.outerBox}>
      <div style={styles.box}>
        {props.checkoutState.state === WHO_STATE && <CustomerInfo /> }
        {props.checkoutState.state === THANK_STATE && <Thanks />}
      </div>
    </div>
  )
};


export default compose(
  withRouter,
  withFirebase,
  connect((state, ownProps) => {
    return {
      ...ownProps,
      checkoutState : state.checkoutState
    }
  })
)(Checkout);
import { combineReducers } from 'redux';
import sessionReducer from './session';
import userReducer from './user';
import inputReducer from "./input";
import checkoutReducer from './checkout';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  inputState : inputReducer,
  checkoutState : checkoutReducer
});

export default rootReducer;
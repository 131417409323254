import React, { Component } from 'react'
import {Route, BrowserRouter, Switch} from 'react-router-dom'
import history from './components/Session/history';
import * as ROUTES from "./constants/routes";
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import LandingPage from './components/LandingPage';
import CustomerInfo from './components/Checkout/CustomerInfo';
import Footer from "./components/Footer";

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount () {
    this.removeListener()
  }

  render() {
    return(
      <BrowserRouter history={history}>
        <div>
          <Switch>
            <Route path='/fire/:mode?/:oobCode?/:apiKey?/:continueUrl?/:lang?' component={LandingPage} />
            <Route path={ROUTES.SIGN_IN} component={Login} />
            <Route path={ROUTES.SIGN_UP} component={Register} />
            <Route path={ROUTES.CLIENT_INFO} component={CustomerInfo} />
            <Route path={ROUTES.LANDING} component={LandingPage} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;

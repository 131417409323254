import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink } from './Register';
import { PasswordForgetLink } from './PasswordForget';
import { withFirebase } from '../Firebase';
import GoogleButton from 'react-google-button'
import {Form, Input, Modal} from "antd";


const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;


const Login = () => (
  <div style={{margin:"0 auto",marginTop:"90px", textAlign:"center"}}>
    <h1>Sign In</h1>
    <SignInForm />
    <SignInGoogle />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const [form] = Form.useForm();

    const isInvalid = password === '' || email === '';

    return (
      <Form
        form={form}
        layout={'vertical'}
        initialValues={{
          layout: 'vertical',
        }}
      >
        <Form.Item label="Email Address" name="Email Address">
          <Input placeholder="Email Address" />
        </Form.Item>
        <Form.Item label="Password" name="Password">
          <Input placeholder="Password" type="password" />
        </Form.Item>
        <button onClick={this.onSubmit} disabled={isInvalid} style={{marginTop:"8px"}} type="submit">
          Sign In
        </button>
        {error && <p>{error.message}</p>}

      </Form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <div style={{marginBottom:"26px"}}>
        <div className="separator">OR</div>
        <div style={{margin:'0 auto', width:"235px"}} >
        <GoogleButton onClick={this.onSubmit} />
        {error && <p>{error.message}</p>}
        </div>
      </div>
    );
  }
}

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default Login;

export { SignInForm, SignInGoogle };

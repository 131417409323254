import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from "../../constants/routes";
import { withFirebase } from '../Firebase';
import ReactGA from 'react-ga';
import {SignInGoogle} from "./Login";


const SignUpPage = () => (
  <div style={{margin:"0 auto", textAlign:"center"}}>
    <h1>Register</h1>
    <Register />
    <SignInGoogle />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne} = this.state;
    const roles = [];

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          roles,
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/');
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <div style={{marginTop:"90px"}}>
        <h1>Register Today!</h1>
        <h4>15 days free, no obligation.</h4>
      <form style={{marginTop:"16px"}} onSubmit={this.onSubmit}>
        <input
          style={{margin:"0 auto", marginTop:"16px"}}
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Full Name"
        />
        <input
          style={{margin:"0 auto", marginTop:"16px"}}
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <input
          style={{margin:"0 auto", marginTop:"16px"}}
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <input
          style={{margin:"0 auto", marginTop:"16px"}}
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
        />

        <span style={{display:"block", marginTop:"12px"}}>By signing up for TariffOn you agree to our <Link to={ROUTES.TERMS_OF_SERIVCE}>Terms of Service</Link></span>

        <button disabled={isInvalid} type="submit" style={{marginTop:"16px"}} onClick={ReactGA.event({
            category: 'accountCreated',
            action: 'Sign Up'
          })}>
          Sign Up
        </button>

        {error && <p>{error.message}</p>}
      </form>
      </div>
    );
  }
}


const SignUpLink = () => (
  <p>
    Don't have an account? <Link to='/register'>Sign Up now</Link>
  </p>
);
const Register = withRouter(withFirebase(SignUpFormBase));
export default SignUpPage;
export { Register, SignUpLink };

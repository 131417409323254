export const SET_TIME = "SET_TIME";
export const SET_TIME_MESSAGE = "SET_TIME_MESSAGE";
export const SET_COOKIE_MESSAGE = "SET_COOKIE_MESSAGE";
export const SET_DAY_MESSAGE = "SET_DAY_MESSAGE";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_ZIP = "SET_ZIP";
export const SET_UNIT = "SET_UNIT";
export const SET_NOTE = "SET_NOTE";
export const SET_PHONE = "SET_PHONE";
export const SET_NAME = "SET_NAME";
export const SET_TIP = "SET_TIP";
export const PLACE_ORDER = "PLACE_ORDER";
export const SET_NAME_MESSAGE = 'SET_NAME_MESSAGE';
export const SET_PHONE_MESSAGE = 'SET_PHONE_MESSAGE';
export const  SET_ZIP_MESSAGE = 'SET_ZIP_MESSAGE';
export const SET_ADDRESS_MESSAGE = 'SET_ADDRESS_MESSAGE';
export const SET_COVER = 'SET_COVER';
export const SET_CUSTOMER = 'SET_CUSTOMER';

//Calendar constats
export const SET_CALENDAR = 'SET_CALENDAR';
export const SET_PDF_BLOB = 'SET_PDF_BLOB';
export const GET_PDF = 'GET_PDF';
export const SET_PDF = 'SET_PDF';

//Goal constants
export const SET_GOAL_TEXT = 'SET_GOAL_TEXT';
export const SET_GOAL_TIME = 'SET_GOAL_TIME';
export const SET_GOAL_DISPLAY = 'SET_GOAL_DISPLAY';
export const ADD_GOAL_TIME = 'ADD_GOAL_TIME';
export const SET_GOAL_RECURRENCE = 'SET_GOAL_RECURRENCE';
export const SET_GOAL_DAY = 'SET_GOAL_DAY';
export const ADD_GOAL = 'ADD_GOAL';
export const MINUS_GOAL_TIME = 'MINUS_GOAL_TIME';
export const DELETE_GOAL = 'DELETE_GOAL';

// Checkout constants
export const SET_CHECKOUT_STATE = 'SET_CHECKOUT_STATE';
export const GOAL_STATE = 'GOAL_STATE';
export const COVER_STATE = 'COVER_STATE';
export const PAY_STATE = 'PAY_STATE';
export const WHO_STATE = 'WHO_STATE';
export const PREVIEW_STATE = 'PREVIEW_STATE';
export const THANK_STATE = 'THANK_STATE';

// Error Handling
export const SET_MESSAGE = 'SET_MESSAGE';

//Order page view
export const ADD_LISTENER = 'ADD_LISTENER';
export const SET_END = 'SET_END';
export const SET_START = 'SET_START';
export const DROP_LISTENERS = 'DROP_LISTENERS';
export const LOAD_ORDERS = 'LOAD_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDERS = 'ADD_ORDERS';
import React from 'react';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withFirebase} from "../Firebase";
import {withRouter} from "react-router-dom";


const styles ={
  box:{fontFamily:"Rubik, sans-serif"},
  label: {fontWeight:"600", fontSize:"22px"},
  inputContainer: {width:"90%", marginBottom:"-5px"},
  day : {
    border:'1px solid black',
    borderRadius:'22px',
    width:'24px', height:'24px',
    fontSize:'12px',
    textAlign:'center', alignItems:'center',
    marginLeft:'2px',
    flexGrow:1,
    cursor:'pointer'
  },
  inactiveDay : {
    borderColor: 'black',
    fontColor: 'black'
  },
  activeDay : {
    borderColor: 'blue',
    color: 'blue'
  }
}


const Thanks=(props)=> {
  return (
    <div style={styles.box}>
      Thank you for joining our email list! We will send you an email when we have more to share.
    </div>
  )
};


export default compose(
  withRouter,
  withFirebase,
  connect((state, ownProps) => {
    return {...state, ...ownProps}
  })
)(Thanks);
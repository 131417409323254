import store from '../../store';
const app = require('firebase/app');
require('firebase/auth');
require('firebase/database');
require('@firebase/functions');
require('dotenv').config();

const config = {
  apiKey: "AIzaSyCgubt4gmzoaSnsrpuabC_TJRBni6-EAb0",
  authDomain: "dyslexicagendas-534b3.firebaseapp.com",
  databaseURL: "https://dyslexicagendas-534b3.firebaseio.com",
  projectId: "dyslexicagendas-534b3",
  storageBucket: "dyslexicagendas-534b3.appspot.com",
  messagingSenderId: "414920773861",
  appId: "1:414920773861:web:cf59a93a688d620700040c",
  measurementId: "G-826TJZEJMZ"
};

class Firebase {
  constructor() {
    console.log(config);
    app.initializeApp(config);


    /* Helper */
    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.database();
    this.functions = app.functions();
    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  functions = () => this.functions;

  addEmail = (email, name)=>{
    let obj = {email, name};
    return this.db.ref(`/email/`)
      .push(obj);
  }

  createOrder = (token, state) =>{
    let order = {
      name: state.name,
      address: state.address,
      zip: state.zip,
      cookies : state.cookies,
      token: token,
      day: state.day,
      tip: state.tip,
      note: state.note
    }
    console.log("order is")
    console.log(order)
    return this.db.ref(`/order/`)
      .push(order);
  }

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () => {
    return this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_EMAIL_URL+this.auth.currentUser.email,
    });
  };


  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  getUserAuthToken = () => this.auth.currentUser.getIdToken(true);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            if(dbUser)
              store.dispatch({type:"SET_PROJECTS", payload: {projects:dbUser.projects }});
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');


}

export default Firebase;
import {SET_TIME_MESSAGE, SET_COOKIE_MESSAGE, SET_DAY_MESSAGE, SET_CUSTOMER, SET_NAME_MESSAGE, SET_PHONE_MESSAGE, SET_ZIP_MESSAGE, SET_ADDRESS_MESSAGE} from '../constants/actions';

function isValidDay(day){
  if(day === undefined || day === ""){
    return false;
  }
  return day.valid;
}

function isValidCookie(cookie){
  if(cookie === undefined || cookie === ""){
    return false;
  }
  return cookie.valid;
}

function isValidTime(cookie){
  if(cookie === undefined || cookie === ""){
    return false;
  }
  return  true;
}

function isValidName(name){
  if(name === undefined || name === ""){
    return false;
  }
  return  true;
}

function isValidPhone(phone){
  if(phone === undefined || phone === "" || !/^\d+$/.test(phone)){
    return false;
  }
  return  true;
}

function isValidZip(phone){
  if(phone === undefined || phone === "" || !/^\d+$/.test(phone)){
    return false;
  }
  return  true;
}

function isValidAddress(cookie){
  if(cookie === undefined || cookie === ""){
    return false;
  }
  return  true;
}


const inputMiddleware = ({ dispatch, getState}) => next => action => {
  if (action.type === "SET_CHECKOUT_STATE") {
    let inputs = getState().checkoutState;
    let proceed = true;
    if (!isValidDay(inputs.day)) {
      proceed = false;
      dispatch({type: SET_DAY_MESSAGE, payload: "Please choose a valid day."})
    }

    if (!isValidCookie(inputs.cookies)) {
      proceed = false;
      dispatch({type: SET_COOKIE_MESSAGE, payload: "Please choose your cookies."})
    }

    if (!isValidTime(inputs.time)) {
      proceed = false;
      dispatch({type: SET_TIME_MESSAGE, payload: "Please select a valid time."})
    }

    if (proceed) {
      return next(action);
    }
  }else if(action.type === SET_CUSTOMER){
    let inputs = getState().checkoutState;
    console.log(inputs)
    let proceed = true;

    if(!isValidName(inputs.name)){
      proceed = false;
      dispatch({type: SET_NAME_MESSAGE, payload: {name: "nameMessage", msg:"Please enter your name."}});
    }

    if(!isValidPhone(inputs.phone)){
      proceed = false;
      dispatch({type: SET_PHONE_MESSAGE, payload: {name: "phoneMessage", msg:"Please enter a valid number."}});
    }

    if(!isValidZip(inputs.zip)){
      proceed = false;
      dispatch({type: SET_ZIP_MESSAGE, payload: {name: "zipMessage", msg:"Please choose a zip code in our service area."}});
    }

    if(!isValidAddress(inputs.address)){
      proceed = false;
      dispatch({type: SET_ADDRESS_MESSAGE, payload: {name: "addressMessage", msg:"Please enter a valid address."}});
    }


    if(proceed){
      return next(action);
    }
  } else {

    return next(action)
  }
};

export default inputMiddleware;
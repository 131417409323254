import React, { useState } from 'react';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {
  SET_PHONE,
  SET_NAME,
  SET_CHECKOUT_STATE,
  THANK_STATE
} from '../../constants/actions';
import {withFirebase} from "../Firebase";
import {Button, Input, Form} from "antd";
import {withRouter} from "react-router-dom";

const styles ={
  box:{fontFamily:"Rubik, sans-serif"},
  label: {fontWeight:"600", fontSize:"22px"},
  inputContainer: {width:"90%", marginBottom:"-5px"}
}


const CustomerInfo=(props)=> {
  const [form] = Form.useForm();

  return (
    <div style={styles.box}>
      <div style={{fontSize:'24px', marginBottom:'12px'}}>Please feel free to sign up for our email list and we will notify you when we have more to share.</div>
      <Form
        form={form}
        layout={'vertical'}
        initialValues={{
          layout: 'vertical',
        }}
      >
        <div style={styles.label}>
          Your Email Address
        </div>
        <div style={styles.inputContainer}>
          <Form.Item name="Phone">
            <Input placeholder="ex: info@dyslexicagendas.com" style={{border:"1px solid black"}}
                   value={props.checkoutState.phone}
                   onChange={(e) => {props.dispatch({type:SET_PHONE, payload:e.target.value})}}
            />
          </Form.Item>
        </div>
        <div style={styles.label}>
          Name
        </div>
        <div style={styles.inputContainer}>
          { props.checkoutState.nameMessage !== "" && <div>{props.checkoutState.nameMessage}</div>}
          <Form.Item name="Name">
            <Input placeholder="ex: Rudolph" style={{border:"1px solid black"}}
                   value={props.checkoutState.name}
                   onChange={(e) => {props.dispatch({type:SET_NAME, payload:e.target.value})}}
            />
          </Form.Item>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',marginTop:'24px' }}>
          <Button type="primary" onClick={()=>{
            props.firebase.addEmail(props.checkoutState.phone, props.checkoutState.name);
            props.dispatch({type:SET_CHECKOUT_STATE, payload: THANK_STATE});
          }}>
            Continue
          </Button>
        </div>
      </Form>
    </div>
  )
};


export default compose(
  withRouter,
  withFirebase,
  connect((state, ownProps) => {
    return {...state, ...ownProps}
  })
)(CustomerInfo);
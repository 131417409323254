import React from 'react';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withFirebase} from "../Firebase";
import Checkout from '../Checkout'

const styles= {
  container:{
    border:"4px solid #ffca6e",
    height:"240px",
    borderRight: "0px",
    borderLeft: "0px"
  },
  innerContainer:{
    width: "90%",
    margin:"0 auto",
    display:"flex",
  },
  leftContainer:{
    flexGrow:"3",
    marginLeft:"12px"
  },
  rightContainer:{
    flexGrow:"1"
  },
  aboutText:{
    fontWeight:"bold",
    fontSize:"18px",
    width:"300px"

  }
};


const Footer=(props)=> {

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
      <div style={styles.leftContainer}>
        <div style={styles.aboutText}>
          Buy chocolate chip cookies online and we deliver them warm to your home or office.
        </div>
        <div>
          <div style={{display:"inline-block"}}>About Us </div>
          <div style={{display:"inline-block"}}>Large Orders </div>
          <div style={{display:"inline-block"}}>News </div>
          <div style={{display:"inline-block"}}>Service Area</div>
        </div>
      </div>
      <div style={styles.rightContainer}>
        Contact
        Insta
        Phone
      </div>

      </div>
    </div>
  )
};


export default compose(
  withFirebase,
  connect((state, ownProps) => {
    return {
      ...ownProps
    }
  })
)(Footer);
import {
  SET_TIME, SET_TIME_MESSAGE, SET_COOKIE_MESSAGE,
  SET_DAY_MESSAGE, SET_ADDRESS, SET_UNIT, SET_NOTE, SET_NAME,
  SET_PHONE, SET_TIP, PLACE_ORDER, SET_ZIP, SET_ADDRESS_MESSAGE,
  SET_ZIP_MESSAGE, SET_NAME_MESSAGE, SET_PHONE_MESSAGE, SET_COVER, WHO_STATE, SET_CHECKOUT_STATE
} from '../constants/actions';

const INITIAL_STATE = {
  day : "",
  time : "",
  cookies : { currentDate: "1 Box", text:"1 Box", valid: true, value: 1},
  state: WHO_STATE,
  dayMessage : "",
  cookieMessage:"",
  timeMessage : "",
  addressMessage:"",
  zipMessage:"",
  phoneMessage:"",
  nameMessage:"",
  address : "",
  unit : "",
  note : "",
  phone:"",
  tip: {value: 0, currentDate: 0, valid: true},
  zip : "",
  cover: {currentDate: 1},
};

const handleInput = (state, action) => {
  return { ...action.payload }
};

const handleDay = (state, action) =>{
  return { ...state, day: action.payload, dayMessage:"", cookieMessage:"" };
}

const handleCover = (state, action) =>{
  return { ...state, cover: action.payload, dayMessage:"", cookieMessage:"" };
}

const handleCookies = (state, action) => {
  return { ...state, cookies : action.payload };
}

const handleCheckout = (state, action) =>{
  return { ...state, state : action.payload };
}

function checkoutReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_INVALID_INPUT": {
      return handleInput(state, action);
    }
    case "SET_DAY":{
      return handleDay(state, action);
    }
    case SET_COVER:{
      return handleCover(state, action);
    }
    case "SET_COOKIES":{
      return handleCookies(state, action);
    }
    case SET_TIME :{
      return { ...state, time:action.payload, timeMessage: "" };
    }
    case SET_CHECKOUT_STATE:{
      return handleCheckout(state, action);
    }
    case SET_ADDRESS :{
      return { ...state, address : action.payload }
    }
    case SET_ZIP :{
      return { ...state, zip: action.payload}
    }
    case SET_UNIT : {
      return { ...state, unit : action.payload }
    }
    case SET_NOTE:{
      return { ...state, note: action.payload }
    }
    case SET_NAME:{
      return { ...state, name: action.payload }
    }
    case SET_PHONE:{
      return { ...state, phone: action.payload }
    }
    case SET_TIP:{
      return { ...state, tip: action.payload }
    }
    case PLACE_ORDER:{
      return { ...state }
    }
    case "SET_BACK" : {
      return {...state, state:action.payload}
    }
    default:
      return state;
  }
}

export default checkoutReducer;
const INITIAL_STATE = {
  users: null,
};

const applySetUsers = (state, action) => ({
  ...state,
  users: action.users,
});

const applySetUser = (state, action) => ({
  ...state,
  users: {
    ...state.users,
    [action.uid]: action.user,
  },
});

const cancelUserSub = (state, action) =>{
  console.log("cancelling");
  action.payload.firebase.functions.httpsCallable('cancelSubscription')({})
    .then((m) => {
      console.log(m);
    })
    .catch((e) => {
      console.log(e);
    });

  return {
    ...state
  }
};


function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USERS_SET': {
      return applySetUsers(state, action);
    }
    case 'USER_SET': {
      return applySetUser(state, action);
    }
    case 'CANCEL_SUB':{
      return cancelUserSub(state, action);
    }
    default:
      return state;
  }
}

export default userReducer;
const INITIAL_STATE = {
  invalid: [],
  headers : {}
};

const handleInput = (state, action) => {
  return { ...action.payload }
};

function inputReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_INVALID_INPUT": {
      return handleInput(state, action);
    }
    default:
      return state;
  }
}

export default inputReducer;
import React from 'react';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withFirebase} from "../Firebase";
import Checkout from '../Checkout'
import './index.css';

const styles= {
  calculatePage:{
    position:"relative",
    margin : "0 Auto",
    width: "90%",
    marginTop:"98px"
  },
  box:{
    color: "#000",
    fontSize:"32px",
    fontWeight:"300",
    maxWidth:"530px",
    marginBottom:"50px",
    paddingLeft:"15px",
    paddingRight:"15px",
    paddingBottom:"12px",
    paddingTop:"12px",
    borderRadius: "24px",
    backgroundColor:"#f7c845",
  },
  outerBox:{
    fontSize:"40px",
    fontWeight:"300",
    paddingLeft:"15px",
    paddingRight:"15px",
    paddingBottom:"12px",
    paddingTop:"12px",
    borderRadius: "24px",
    backgroundColor:"#faf5ea",
    marginBottom:"90px",
    maxWidth:"750px",
    minWidth:"650px"
  },
  innerBox:{
    color: "#ec5628",
    fontFamily: "Rubik Mono One, sans-serif",
    borderRadius: "14px",
    paddingLeft:"15px",
    paddingTop:"12px"
  },
  title :{
    color: "#ec5628",
    fontFamily: "Rubik Mono One, sans-serif",
  },
  pricingText:{
    fontWeight:"bold",
    float:"right",
    right:"0px"
  },
  containerText:{
    display:"block",
    width:"90%",
    marginBottom:"8px"
  }
};

const getDescriptionReg = () => {
  return (
    <div style={styles.outerBox}>
      <div style={styles.innerBox}>
        <div style={{
          marginLeft: "12px"
        }}>
          How To Use
        </div>
        <div style={{...styles.innerBox, paddingLeft:"0px"}}>
          <div style={{fontFamily: "Rubik, sans-serif", fontSize:"22px"}}>
            <ol>
              <li> Tell people about your day and whats coming up in your life.</li>
              <li> Write tasks down when you learn about them.</li>
              <li> Consistency is paramount, keep your agenda on you.</li>
              <li> Reflect on everything you have done today.</li>
              <li>Write down something you are doing tomorrow.</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

const getPricing = ()=>{
  return (
    <div style={{...styles.outerBox, maxWidth:"580px"}}>
      <div style={styles.innerBox}>
        <div style={{}}>
          Pricing
        </div>
        <div style={{...styles.innerBox, paddingLeft:"0px"}}>
          <div style={{fontFamily: "Rubik, sans-serif", fontSize:"24px"}}>
            <div style={styles.containerText}>Digital Agenda  <span style={{fontWeight:"bold"}}>(3 Months) </span> <span style={styles.pricingText}>. . . Coming Soon</span></div>
            <div style={styles.containerText}>Physical Agenda  <span style={{fontWeight:"bold"}}>(3 Months) </span> <span style={styles.pricingText}>. . . Coming Soonish</span></div>
          </div>
        </div>
      </div>
    </div>)
}


const LandingPage=(props)=> {

  return (
    <div style={styles.calculatePage}>
      <div style={styles.box}>
        <div style={{...styles.innerBox,width:"480px"}}>
          <div className={"titleText"} style={{width:"480px", display:"block", textAlign: "justify", height:"36px"}}>
            AGENDAS DESIGNED
          </div>
          <div className={"titleText"} style={{width:"480px", display:"block", textAlign: "justify", height:"42px"}}>
            FOR DYSLEXIA
          </div>
          <span style={{display: "block", fontSize: "22px", fontFamily: "Rubik, sans-serif"}}>
            WRITE OUT YOUR LIFE
          </span>
        </div>
      </div>
      <Checkout />

      { getDescriptionReg() }
      { getPricing() }

    </div>
  )
};


export default compose(
  withFirebase,
  connect((state, ownProps) => {
    return {
      ...ownProps
    }
  })
)(LandingPage);